<template>
  <FactorForm
    :factor="factor ? factor : getCurrentFactor()"
    :type="'edit'"
    @save="updateFactor"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    FactorForm: () => import('./FactorForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    factor: { type: 'factor' },
    askToLeave: true,
  }),
  methods: {
    getCurrentFactor() {
      this.$api
        .get(`product_factors/${this.$route.params.id}`)
        .then((res) => {
          this.factor = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateFactor() {
      this.askToLeave = false
      this.$api
        .post(`product_factors/${this.$route.params.id}`, this.factor)
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Gravação atualizado com sucesso',
            confirm: () => {
              this.$router.push({ name: 'factor' })
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar atualizar este gravação',
            caption: 'Verifique se os campos estão preenchidos corretamente.',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
  },
  created() {
    this.getCurrentFactor()
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
